.ant-menu-item.ant-menu-item-only-child.ky_menu_item.ant-menu-item-selected {
  background: $rng-linear-3 !important;
  color: $rng-10;
  font-weight: 700;
  border: 1px solid $rng-11;
}

.ant-menu-item {
  &.ant-menu-item-only-child {
    &.ky_menu_item {
      color: $blck-68;
      font-weight: 500;
      margin: 0;
      padding: 0 10px !important;
      line-height: 48px;
      margin-top: -1px;
      margin-bottom: -1px;
      padding-top: 1px !important;
      padding-bottom: 1px !important;
      border: 1px solid transparent;
      @include transition(0s);
      &:hover{
        background: $rng-linear-3 !important;
        color: $rng-10;
        border: 1px solid $rng-11;
      }
    }
  }
}
.ant-menu{
  .ky_menu_divider {
    margin: 0 1.5rem;
    background-color: $gry-e3;
  }
}
.ant-menu.ant-menu-dark.ky_menu.ant-menu-root.ant-menu-inline {
  background-color: $trnsp;
  color: $blck;
}

.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ky_menu.ant-menu-root.ant-menu-vertical {
  background-color: inherit;
  color: $blck;
}

.ant-layout-content.Kyu-content {
  background-color: $gry-fe;
  padding: 1.5rem;
}

.ant-switch-checked {
  background-color: $rng-6;
}

.anticon.anticon-user {
  > svg {
    color: $rng-6;
  }
}

.ant-table-thead{
  background: transparent !important;
}

.ky_tb_nv .ant-tabs-tab.ant-tabs-tab-active{
  background: $drk-linear;
  border-color: $rng-1;
  .ant-tabs-tab-btn{
      color:$wht;
  }
}

.ant-tabs-nav{
  margin-top: 1.5rem !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #FCF4F2 !important;
  @media(max-width:767px){
    background-color: #FCF4F2 !important;
  }
}

.ant-radio-checked .ant-radio-inner{
  border-color: $rng-1 !important ;
}

.ant-radio-checked .ant-radio-inner:after{
  background-color: $rng-1;
}

.ant-radio:hover .ant-radio-inner {
  border-color: $rng-1 ;
}

.ant-drawer-body{
  background-color: $gry-fa;
}

.ant-spin-dot-item {
  background:$rng-linear;
}

.ant-divider-horizontal{
  margin: 1rem 0;
}

.ant-btn.ant-btn-primary.ant-input-search-button{
  background:$rng-linear-4;
  height: 49.6px;
}

.ant-input-group-wrapper.ant-input-search.ant-input-search-with-button{
  width: 80%;
  margin-left: 80px;
}

.ant-notification-notice-icon * {
  font-size: 24px;
}